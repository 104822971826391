<template>
  <b-button :to="to" :href="href" :size="size" :variant="variant" :loading="loading" :disabled="disabled">
    <div v-if="loading" class="loader"></div>
    <slot></slot>
  </b-button>
</template>

<script>
export default {
  name: 'LoadingButton',
  props: ['size', 'variant', 'href', 'to', 'loading', 'disabled']
};
</script>

<style lang="scss" scoped>
.loader {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  position: relative;
  display: inline-block;
  margin-right: 0.5em;
  margin-bottom: -0.15em;
  text-indent: -9999em;
  border: 0.1em solid currentColor;
  border-left-color: transparent;
  transform: translateZ(0);
  animation: spin 0.75s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
