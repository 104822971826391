import Axios from 'axios';
import config from '@/config';

const baseURL = config.API_URL;

export const axios = Axios.create({
  baseURL
});

let token = '';

export const setToken = (jwt: string): void => {
  token = jwt;
};

axios.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['rb-client'] = 'player-portal';
  }
  return config;
});
