<template>
  <ValidationProvider>
    <b-form-group id="input-group-age" label-for="age" label="Age">
      <b-form-select v-model="selectedAge" name="age" :options="ageRangeOptions" data-vv-as="age"></b-form-select>
    </b-form-group>
  </ValidationProvider>
</template>

<script lang="ts">
import Vue from 'vue';

const ageRanges = [
  { value: null, text: '--- Select an age range ---' },
  { value: '18-34', text: '18-34' },
  { value: '35-49', text: '35-49' },
  { value: '50-64', text: '50-64' },
  { value: '65-74', text: '65-74' },
  { value: '75+', text: '75+' }
];

export default Vue.extend({
  name: 'AgeSelect',
  data() {
    return {
      selectedAge: null,
      ageRangeOptions: ageRanges
    };
  },
  watch: {
    selectedAge() {
      this.$emit('age-input', this.selectedAge);
    }
  }
});
</script>
