<template>
  <div class="rb-alert" :class="classType">
    <div class="rb-alert__icon">
      <b-icon v-if="icon" :icon="icon" :variant="variant" font-scale="3"></b-icon>
    </div>
    <div class="rb-alert__msg">
      <div class="rb-alert__header">
        <slot name="heading"></slot>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: ['icon', 'fontScale', 'variant'],
  computed: {
    classType() {
      let classString = `rb-alert__${this.variant}`;
      if (this.icon) {
        classString += ' rb-alert--margin';
      }
      return classString;
    }
  }
};
</script>

<style lang="scss" scoped>
.rb-alert {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1.5rem 3rem;
  padding-left: 2rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-left: 0.5rem solid #56b65e;
  border-radius: 0.5rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  text-align: left;

  &--margin {
    margin-left: 1.2rem;
  }

  &__icon {
    position: absolute;
    flex: 0 1 auto;
    left: -1.75rem;
    background-color: #ffffff;
    border-radius: 50%;
  }

  &__header {
    font-size: 1.25rem;
    font-weight: 700;
  }

  &__icon,
  &__header {
    &:empty {
      display: none;
    }
  }

  &__success {
    border-color: #56b65e;
  }

  &__danger {
    border-color: #dc3545;
  }

  &__warning {
    border-color: #f6ae2d;
  }

  &__info {
    border-color: #8075ff;
  }

  &__primary {
    border-color: #007bff;
  }

  &__secondary {
    border-color: #58595b;
  }

  &__light {
    border-color: #e9ecef;
  }

  &__dark {
    border-color: #343a40;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 36em) {
    flex-direction: column;
    margin-top: 2rem;
    padding: 2rem 1rem;
    border-left-width: 1px;
    border-top-width: 0.5rem;

    &__icon {
      top: -1.75rem;
      left: inherit;
    }
  }
}
</style>
