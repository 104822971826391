<template>
  <div></div>
</template>
<script>
import { setOptions, bootstrap } from 'vue-gtag';
export default {
  props: ['pixelCode', 'gtmCode'],
  mounted() {
    if (this.gtmCode && this.gtmCode.includes('GTM')) {
      this.enableGtm(this.gtmCode);
    } else {
      this.enableGa(this.gtmCode);
    }
    if (this.pixelCode) {
      this.enableFbPixel(this.pixelCode);
    }
  },
  methods: {
    enableGa(gtmCode) {
      setOptions({
        config: {
          id: gtmCode,
          params: {
            send_page_view: true
          }
        }
      });

      bootstrap().then((gtag) => {
        return gtag;
      });
    },
    enableGtm() {
      // Manually include customer GTM containers in our checkout flow
      const gtmCode = this.gtmCode;

      const firstScript = document.getElementsByTagName('script')[0];
      const gtmScript = document.createElement('script');
      gtmScript.async = true;
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmCode}`;
      firstScript.parentNode?.insertBefore(gtmScript, firstScript);
    },
    enableFbPixel(pixelCode) {
      if (pixelCode) {
        this.$analytics.fbq.init(pixelCode);
        this.$analytics.fbq.event('PageView');
      }
    }
  }
};
</script>
