import axios from '@/lib/axios-api';
import { Event } from '@/lib/schema';
import config from '@/config';

const contextRoot = '/event-service/v2';

const readEvent = async (eventId: string): Promise<Event> => {
  // TODO Add axios retry
  const response = await axios({
    url: `${contextRoot}/events/${eventId}?childEvents=true`,
    baseURL: config.API_CACHE_URL
  });

  const event = response.data.data.data as Event;

  return event;
};

export default {
  readEvent: readEvent
};
