<template>
  <ValidationProvider>
    <b-form-group id="input-group-title" label-for="title" label="Title">
      <b-form-select v-model="selectedTitle" name="title" :options="titleOptions" data-vv-as="title"></b-form-select>
    </b-form-group>
  </ValidationProvider>
</template>

<script lang="ts">
import Vue from 'vue';

const titleOptions = [
  { value: null, text: '--- Select a title ---' },
  { value: 'Mr.', text: 'Mr.' },
  { value: 'Mrs.', text: 'Mrs.' },
  { value: 'Ms.', text: 'Ms.' },
  { value: 'Mx.', text: 'Mx.' },
  { value: 'Miss', text: 'Miss' },
  { value: 'Dr.', text: 'Dr.' }
];

export default Vue.extend({
  name: 'TitleSelect',
  data() {
    return {
      selectedTitle: null,
      titleOptions
    };
  },
  watch: {
    selectedTitle() {
      this.$emit('title-input', this.selectedTitle);
    }
  }
});
</script>
