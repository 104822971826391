<template>
  <ValidationProvider v-slot="ageCheck" name="age confirmation" rules="required">
    <b-form-group id="input-group-age-check" class="mb-1" label-for="input-age-check">
      <!-- eslint-disable vue/no-mutating-props -->
      <b-form-checkbox
        v-model="customer.ageCheck"
        :state="getValidationState(ageCheck)"
        aria-describedby="input-group-age-check-feedback"
        :unchecked-value="null"
      >
      <!-- eslint-enable vue/no-mutating-props -->
        <p class="mb-1">{{ verificationMessaging }}</p>
      </b-form-checkbox>
      <b-form-invalid-feedback id="input-group-age-check-feedback">{{ ageCheck.errors[0] }}</b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>

<script lang="ts">
interface Flags {
  [k: string]: boolean | null;
}

import { getProvinceStateOptions } from '@/lib/province-options';
import { Customer } from '@/lib/schema';

import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'AgeCheck',

  props: {
    province: {
      type: String,
      required: true
    },

    country: {
      type: String,
      required: true
    },

    validatePostalCodeLocation: {
      type: Boolean,
      default: false
    },

    customer: {
      type: Object as PropType<Customer>,
      required: true
    }
  },

  computed: {
    verificationMessaging() {
      const provinceInfo = getProvinceStateOptions(this.country, this.province).shift();
      let message = '';
      if (provinceInfo) {
        if (this.province === 'ON') {
          message = `${this.$i18n.t('AgeCheckMessages.Ontario', { age: provinceInfo.minimumAge })}`;
        } else if (this.validatePostalCodeLocation && this.country !== 'US') {
          const translationKey = `AgeCheckMessages.${provinceInfo.name}`;

          message = `${this.$i18n.t(translationKey, { age: provinceInfo.minimumAge })}`;
        } else if (this.province === 'MB') {
          message = `${this.$i18n.t('AgeCheckMessages.Manitoba', { age: provinceInfo.minimumAge })}`;
        } else {
          message = `${this.$i18n.t('AgeCheckMessages.generic', { age: provinceInfo.minimumAge })}`;
        }
      }
      return message;
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }: Flags) {
      return dirty || validated ? valid : null;
    }
  }
});
</script>
