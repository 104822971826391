import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Raffle from '@/pages/Raffle.vue';
import Addon from '@/pages/Addon.vue';
import Single from '@/pages/Single.vue';
import Confirm from '@/pages/Confirm.vue';
import Payment from '@/pages/Payment.vue';
import Success from '@/pages/Success.vue';
import Goldrush from '@/pages/Goldrush.vue';
import Status from '@/pages/Status.vue';

Vue.use(VueRouter);

function lazyLoad(view: string) {
  return () => import(`@/pages/${view}.vue`);
}

const routes: Array<RouteConfig> = [
  {
    path: '/raffle',
    name: 'Raffle',
    component: Raffle
  },
  {
    path: '/addon',
    name: 'Addon',
    component: Addon
  },
  {
    path: '/confirm',
    name: 'Confirm',
    component: Confirm
  },
  {
    path: '/single',
    name: 'Single',
    component: Single
  },
  {
    path: '/goldrush',
    name: 'Goldrush',
    component: Goldrush
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/success',
    name: 'Success',
    component: Success
  },
  {
    path: '/status',
    name: 'Status',
    component: Status
  },
  {
    path: '*',
    name: 'Not Found',
    component: lazyLoad('404')
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

export default router;
