export interface ShoppingCartItem {
  id: string;
  eventId: string;
  quantity: number;
  priceCents: number;
  series: string;
  numTickets: number;
}

export interface AddShoppingCartItem {
  id: string;
  eventId: string;
  quantity: number;
  priceCents: number;
  series: string;
  numTickets: number;
}

import store from '@/store';

export class ShoppingCart {
  #cartItems: Array<AddShoppingCartItem> = [];

  totalCents = 0;
  numDraws = 1;
  donationAmount = 0;

  get count(): number {
    return this.#cartItems.length;
  }

  add(addItem: AddShoppingCartItem, addQuantity = 1): ShoppingCartItem {
    const foundItem = this.#cartItems.find((i) => i.id === addItem.id);

    if (foundItem) {
      // update the quantity
      foundItem.quantity += addQuantity;

      this.totalCents += addItem.priceCents * addQuantity;

      return foundItem;
    } else {
      const item: ShoppingCartItem = {
        id: addItem.id,
        eventId: addItem.eventId,
        priceCents: addItem.priceCents,
        quantity: addQuantity,
        numTickets: addItem.numTickets,
        series: addItem.series
      };

      this.totalCents += addItem.priceCents * addQuantity;

      this.#cartItems.push(item);

      return item;
    }
  }

  remove(itemId: string): ShoppingCartItem | null {
    const item = this.#cartItems.find((i) => {
      return i.id === itemId;
    });

    if (item) {
      item.quantity--;
      this.totalCents -= item.priceCents;

      if (item.quantity === 0) {
        if (this.#cartItems.indexOf(item) >= 0) {
          this.#cartItems.splice(this.#cartItems.indexOf(item), 1);
        }
      }

      return item;
    }

    return null;
  }

  removeAllById(itemId: string): void {
    const item = this.#cartItems.find((i) => {
      return i.id === itemId;
    });

    if (item) {
      if (this.#cartItems.indexOf(item) >= 0) {
        this.#cartItems.splice(this.#cartItems.indexOf(item), 1);
        this.totalCents = this.totalCents - item.quantity * item.priceCents;
      }
    }
  }

  numberOfDraws(draws: number): void {
    this.numDraws = draws;
  }

  items(): ShoppingCartItem[] {
    return this.#cartItems;
  }

  totalCartCents(): number {
    return this.totalCents * this.numDraws + this.donationAmount;
  }

  totalCart(): number {
    return this.totalCartCents() / 100;
  }

  totalProcessingFeeCents(): number {
    return Math.ceil((this.totalCartCents() * (store.state.event.processingFeePercent || 0)) / 100);
  }

  totalCartWithProcessingFee(): number {
    return (this.totalCartCents() + this.totalProcessingFeeCents()) / 100;
  }

  isEmpty(): boolean {
    return this.#cartItems.length > 0 ? false : true;
  }

  addDonation(donation: number): void {
    this.donationAmount = donation;
  }

  totalDonationCents(): number {
    return this.donationAmount;
  }
}

const shoppingCart = new ShoppingCart();

export default shoppingCart;
