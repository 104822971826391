import Axios from 'axios';
import config from '@/config';

const axios = Axios.create({
  baseURL: config.API_URL
});

axios.interceptors.request.use(function (config) {
  config.headers['rb-client'] = 'checkout';

  return config;
});

export default axios;
