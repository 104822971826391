import Axios from '@/lib/axios-api';
import config from '@/config';

export interface EventMember {
  name: string;
  number: number;
  id: string;
  active: boolean;
  email?: string;
}

interface ReadEventMember extends EventMember {
  groupName: string | null;
  memberName: string;
}

export interface ListEventMembersResponse {
  groupName: string;
  members: EventMember[];
}

export default {
  getSelectedEventMember: async (eventMemberNumber: number, eventId: number): Promise<ReadEventMember> => {
    const response = await Axios.get(`/event-member-service/v2/event-members/${eventMemberNumber}?eventId=${eventId}`, {
      baseURL: config.API_CACHE_URL
    });

    return response.data.data;
  },
  listEventMembers: async (params = {}) => {
    const response = await Axios.get(`/event-service/v2/event-members`, { params });

    return response.data.data;
  },
  listEventMemberSales: async (id: string, params = {}) => {
    const response = await Axios.get(`/event-service/v2/event-members/sales?eventId=${id}`, { params });

    return response.data.data;
  },
  listAllEventMembers: async (eventId: string, pageSize = 1000) => {
    let page = 0;
    let lastPage = false;
    let eventMembers: any[] = [];
    do {
      const response = await Axios.get(`/event-service/v2/event-members`, {
        params: { eventId, pageSize, page }
      });

      eventMembers = [...eventMembers, ...response.data.data.data];

      if (response.data.data.data.length < pageSize) {
        lastPage = true;
      }

      page++;
    } while (!lastPage);

    return eventMembers;
  },

  listAllEventMembersV2: async (
    eventId: string,
    active: boolean | undefined,
    pageSize = 1000,
    sortBy = undefined
  ): Promise<ListEventMembersResponse[]> => {
    const response = await Axios.get(`/event-member-service/v2/event-members`, {
      params: { eventId, pageSize, active, sortBy }
    });

    return response.data.data.data;
  }
};
