import { axios } from '@/lib/axios-player-portal';

export interface PaymentMethod {
  last4: string;
  brand: 'amex' | 'diners' | 'discover' | 'eftpos_au' | 'jcb' | 'mastercard' | 'unionpay' | 'visa' | 'unknown';
  id: string;
  expMonth: string;
  expYear: string;
}

export default {
  retrievePlayer: async () => {
    const response = await axios.get(`/player-service/v2/player`);
    return response.data.data;
  },
  listPaymentMethods: async (): Promise<PaymentMethod[]> => {
    const response = await axios.get('/player-service/v2/payment-methods');

    return response.data.data;
  }
};
