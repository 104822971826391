<template>
  <div>
    <div class="login-container">
      <b-dropdown v-if="player" class="m-md-2 custom-dropdown" right>
        <template #button-content>
          <div class="dropdown-button">
            <p class="dropdown-name">{{ player.firstName }}</p>
            <b-icon-person-circle class="dropdown-icon"></b-icon-person-circle>
          </div>
        </template>
        <b-dropdown-item href="https://play.rafflebox-test.org/login" target="_blank" rel="noopener noreferrer">
          Account Details
        </b-dropdown-item>
        <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
      </b-dropdown>
      <b-button v-else ref="loginButton" v-b-modal.login-modal class="login-button">Login</b-button>
    </div>
    <b-modal
      id="login-modal"
      v-model="showModal"
      no-close-on-backdrop
      size="lg"
      body-class="position-static"
      hide-footer
      class="custom-modal"
      centered
      @hidden="handleModalHidden"
    >
      <b-container class="modal-body">
        <div class="text-center">
          <slot name="logo" class="login-logo-container" />
        </div>

        <RbAlert v-if="loginFailed" variant="danger" icon="exclamation-circle">
          <template #heading>
            <strong>Login failed.</strong>
          </template>
          Having trouble signing in? Email
          <b-link href="mailto:help@rafflebox.ca" class="support-link" target="_blank" rel="noopener">
            help@rafflebox.ca
          </b-link>
        </RbAlert>

        <b-form class="text-left" @submit.stop.prevent="submit">
          <ValidationProvider v-slot="{ errors }" name="Email Address" rules="required|email">
            <b-form-group label="Email Address" label-for="username" label-sr-only class="mb-3">
              <b-form-input
                id="username"
                v-model="username"
                :state="getValidationState(errors)"
                placeholder="Email Address"
              />
              <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <ValidationProvider v-slot="{ errors }" name="Password" rules="required|min:6">
            <b-form-group label="Password" label-for="password" label-sr-only class="mb-3">
              <b-form-input
                id="password"
                v-model="password"
                :state="getValidationState(errors)"
                placeholder="Password"
                type="password"
              />
              <b-form-invalid-feedback v-if="errors.length">{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>

          <div class="text-center">
            <b-button type="submit" class="submit-button">Login</b-button>
          </div>

          <div class="text-center links">
            <a href="https://play.rafflebox.org/forgot-password" target="_blank" rel="noopener noreferrer"
              >Forgot Password?</a
            >
          </div>

          <div class="text-center links">
            <a href="https://play.rafflebox.org/register" target="_blank" rel="noopener noreferrer">Register</a>
          </div>
        </b-form>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { auth } from '@/lib/services/auth';
import PlayerService from '@/lib/player-service';
import RbAlert from '@/components/RbAlert';
import { mapActions } from 'vuex';
import { setToken } from '@/lib/axios-player-portal';
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    RbAlert,
    ValidationProvider
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    showForgotPassword: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      loginFailed: false,
      username: '',
      password: '',
      busy: false,
      showModal: false
    };
  },
  computed: {
    player() {
      return this.$store.getters.player;
    }
  },
  methods: {
    ...mapActions(['setPlayerId', 'setPlayer']),

    async submit() {
      this.loginFailed = false;

      try {
        this.busy = true;

        await auth.signIn(this.username.trim(), this.password);
        const token = await auth.verifyToken();
        const sessionUser = await auth.sessionUser();
        // Set the token in the axios instance
        setToken(token);
        // Set the player id in the store
        this.setPlayerId(sessionUser.id);

        const player = await PlayerService.retrievePlayer();
        this.setPlayer(player);

        this.showModal = false;
      } catch (error) {
        this.loginFailed = true;
        this.$emit('login-error', { errorMessage: error.message });
      } finally {
        this.busy = false;
      }
    },
    async logout() {
      await auth.signOut();
      this.setPlayerId('');
      this.setPlayer(null);
      setToken(null);
      console.log('Logging out');
    },
    resetForm() {
      this.username = '';
      this.password = '';
      this.loginFailed = false;
      this.busy = false;
    },
    removeButtonFocus() {
      this.$nextTick(() => {
        if (this.$refs.loginButton) {
          this.$refs.loginButton.blur();
        }
      });
    },
    handleModalHidden() {
      this.resetForm();
      this.removeButtonFocus();
    },
    getValidationState(errors) {
      return errors.length === 0 ? null : false;
    }
  }
};
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-body {
  padding: 2rem;
}

.login-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 20%;
  padding-bottom: 1.5rem;

  img {
    max-width: 80%;
    height: auto;
  }
}

.app-title {
  padding-bottom: 2rem;
}

.links {
  a {
    color: #2c3e50;
  }
}

.login-button {
  background-color: var(--buttonColor);
  border-color: var(--buttonColor);

  &:hover {
    color: var(--buttonColor) !important;
    background-color: $white !important;
    border-color: var(--buttonColor) !important;
  }
}

.text-center {
  padding-bottom: 1.5rem;
}

.submit-button {
  background-color: #56b65e;
  border-color: #56b65e;

  &:hover {
    color: #56b65e !important;
    background-color: $white !important;
    border-color: #56b65e !important;
  }
}

.support-link {
  color: #721c24;
  text-decoration: underline;
}

.support-link:hover,
.support-link:focus {
  text-decoration: none;
}

.modal-header {
  border-bottom: none !important;
}

@media screen and (max-width: 48em) {
  .app-title {
    font-size: 3.2rem;
  }

  .card-body {
    .login-logo-container {
      img {
        max-width: 100%;
      }
    }
  }

  .card-login {
    padding: 0;
    border: 0;
    box-shadow: none !important;

    .card-body {
      padding: 0;
    }
  }

  .submit-button {
    width: 100%;
  }
}
</style>

<style lang="scss">
.custom-dropdown .btn-secondary {
  background-color: var(--buttonColor) !important;
  border-color: var(--buttonColor) !important;
  margin-left: 2rem;
  display: flex;
  align-items: center;

  &:hover {
    color: var(--buttonColor) !important;
    background-color: $white !important;
    border-color: var(--buttonColor) !important;
  }
}

.custom-dropdown .dropdown-item {
  &:hover {
    background-color: var(--buttonColor) !important;
    color: $white !important;
  }
}

.dropdown-button {
  display: flex;
  // align-items: flex-start;
  // justify-content: flex-start;
}

.dropdown-name {
  margin-right: 0.5rem;
  margin-bottom: 0;
  align-items: center;
}

.dropdown-icon {
  margin-right: 0.5rem;
  align-items: center;
}
</style>
