<template>
    <div v-if="displayDownloadTickets" class="button-container">
      <b-button :disabled="buttonDisabled" variant="outline-success" @click="getDownloadTicketsUrl">
        <b-spinner v-if="buttonDisabled" class="mx-5" small></b-spinner>
        <span v-if="!buttonDisabled">{{ $t('Success.downloadTickets') }}</span>
      </b-button>
    </div>
  </template>
  
  <script lang="ts">
  import Vue from 'vue';
  import TicketServiceV2 from '@/lib/services/tickets-service-v2';
  import download from 'downloadjs';
  import _ from 'lodash';
  import VueCookie from 'vue-cookie';
  import { CheckoutErrorCodes } from '@/lib/error-codes';
  import config from '@/config';
  
  export default Vue.extend({
    name: 'DownloadTicketsButton',
    data() {
      return {
        buttonDisabled: false as boolean,
        event: this.$store.state.event || null,
        orders: this.$route.params.orders ? JSON.parse(this.$route.params.orders) : null,
        paymentIntent: this.$store.state.intent || null,
        orderCookie: null,
        eventCookie: null,
        localeCookie: null,
        errorMessage: undefined as string | undefined,
      };
    },
    computed: {
        displayDownloadTickets() {
        const tld = config.RAFFLEBOX_URL.split('.').pop();
        if (tld === 'us') {
            return false;
        }

        return true;
        }
    },
    mounted() {
        // If there's a payment intent, delete the old cookies and create new ones with the latest info, otherwise just ensure they're set to variables we can access
        if (this.paymentIntent != null) {
        this.deleteCookies();
        this.createCookies();
        this.setCookies();
        } else {
        this.setCookies();
        }

        this.$i18n.locale = this.localeCookie || 'en';
    },
    methods: {
        createCookies() {
            // Use getOrderForTicketsUrl method to find the correct orderId from the array of orders. This will be needed to get the ticket download URL
            const parentRaffleOrderId: string | null = this.getOrderForTicketsUrl();

            VueCookie.set('order', parentRaffleOrderId, { expires: 7 });
            VueCookie.set('event', this.event.name, { expires: 7 });
            VueCookie.set('locale', this.$i18n.locale, { expires: 14 });
        },
        setCookies() {
            this.orderCookie = VueCookie.get('order');
            this.eventCookie = VueCookie.get('event');
            this.localeCookie = VueCookie.get('locale');
        },
        deleteCookies() {
            VueCookie.delete('order');
            VueCookie.delete('event');
            VueCookie.delete('locale');
        },
        getOrderForTicketsUrl() {
            // If there is only one order, use that. If there is more than one, find the parent raffle order. If there are no orders, use the cookie.
            if (this.orders != null && this.orders.length === 1) {
                return this.orders[0].orderIdLong;
            } else if (this.orders != null && this.orders.length > 1) {
                const parentOrder = this.orders.find((order: { eventId: string; orderIdLong: string }) => {
                if (order.eventId === this.event.id) {
                    return order.orderIdLong;
                }
                });

                return parentOrder.orderIdLong;
            } else {
                return this.orderCookie;
            }
        },
        getDownloadTicketsUrl() {
            // Disable the download tickets button and start the loading spinner within it.
            this.buttonDisabled = true;
            // Collect the correct order ID from the array of orders so we can pass it to TicketServiceV2.
            const parentRaffleOrderId: string | null = this.getOrderForTicketsUrl();

            // Below I've created an interval to run the code to fetch the URL once every 5 seconds for 30 seconds.
            // It saves any error it finds to currentError each run through, and at the end of the 30s will display the error to the user.
            // If, on any of the attempts, it succeeds in finding the URL, it will stop the interval and immediately download the ticket PDF from that URL.
            let currentError: any;
            const startTime = new Date().getTime();
            const interval = setInterval(async () => {
                // If it's been 30 seconds or more, display any errors, re-enable the button and stop the interval.
                if (new Date().getTime() - startTime > 30000) {
                if (currentError && currentError['status'] != 200) {
                    this.buttonDisabled = false;
                    this.errorMessage = this.parseError(currentError);
                }
                clearInterval(interval);
                return;
                }

                try {
                const params = {
                    orderId: parentRaffleOrderId,
                    locale: this.$i18n.locale || this.localeCookie
                };

                const response = await TicketServiceV2.getDownloadTicketsUrl(params);
                // If we get a response stop the interval, re-enable the download tickets button and create a file name to pass to the file downloader along with the URL to download from.
                clearInterval(interval);
                this.buttonDisabled = false;
                const url = response.url;
                const date = new Date().toLocaleDateString().replaceAll('/', '-');
                const eventName = this.event.name || this.eventCookie;
                const urlName = `${eventName.replaceAll(' ', '-')}-TICKETS-${date}.pdf`;

                this.downloadFile(url, urlName);
                } catch (error) {
                currentError = error;
                }
            }, 5000);
        },
        // This is straight out of the downloadjs documentation and done this way so we can have a custom filename on the download.
        downloadFile: _.debounce(function (url: string, urlName: string) {
            const httpRequest = new XMLHttpRequest();
            httpRequest.open('GET', url, true);
            httpRequest.responseType = 'blob';
            httpRequest.onload = async function (e: ProgressEvent) {
                const target = e.target as XMLHttpRequest;
                await download(target.response, urlName, 'application/pdf');
            };
            httpRequest.send();
        }, 500),
        parseError(error: { status: number }) {
            if (error.status === 404) {
                return CheckoutErrorCodes.TicketPdfNotFound;
            } else if (error.status === 410) {
                return CheckoutErrorCodes.TicketPdfExpired;
            } else {
                return CheckoutErrorCodes.TicketPdfGenericError;
            }
        }
    },
  });
  </script>
  
  <style scoped>
  .button-container {
    display: flex;
    justify-content: center;
  }
  </style>
  