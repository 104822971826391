<template>
  <div class="donation-area">
    <div class="header">
      <h4>Want to add a donation?</h4>
    </div>
    <ValidationProvider v-slot="donations" name="donation" rules="positive_number">
      <div class="d-flex data-entry">
        <p class="prompt data-entry-item">Enter amount</p>
        <span class="dotted-line data-entry-item"></span>
        <span class="no-wrap data-entry-item">
          <b-form-group id="input-group-donation" class="form-group-override">
            <b-form-input
              id="input-donation"
              v-model="displayedDonationAmount"
              class="input-override"
              :state="getValidationState(donations)"
              placeholder="0.00"
              type="number"
            ></b-form-input>
          </b-form-group>
        </span>
      </div>
      <p class="invalid-input">{{ donations.errors[0] }}</p>
    </ValidationProvider>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { ValidationProvider, extend } from 'vee-validate';
import ProjectMixin from '@/project-mixin';

extend('positive_number', {
  message: 'The amount must be a whole number and greater than 0.',
  validate: (value: any) => {
    const number = parseFloat(value);
    if (!Number.isInteger(number)) {
      return false;
    }
    if (number === 0) return true;
    return number > 0;
  }
});

interface Flags {
  [k: string]: boolean | null;
}

@Component({
  components: {
    ValidationProvider
  }
})
export default class Donation extends Mixins(ProjectMixin) {
  private donationAmountCents = 0;

  get displayedDonationAmount() {
    return this.donationAmountCents / 100;
  }

  set displayedDonationAmount(value: any) {
    this.donationAmountCents = Math.round(value * 100);
  }

  getValidationState({ dirty, validated, valid = null }: Flags) {
    return dirty || validated ? valid : null;
  }

  @Watch('donationAmountCents', { immediate: true, deep: true })
  amountChanged(amountCents: number) {
    const parsedValue = amountCents / 100;
    // If the value is not a whole number or is less than 0, set the donation to 0
    if (!Number.isInteger(parsedValue) || parsedValue < 0) {
      this.shoppingCart.addDonation(0);
    } else {
      this.shoppingCart.addDonation(amountCents);
    }
  }
}
</script>
<style lang="scss" scoped>
.donation-area {
  margin-top: 1rem;
  margin-bottom: 1rem;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #787878;
  border-radius: 10px;
}

.dotted-line {
  min-width: 4rem;
  width: 100%;
  border-bottom: 1px dashed;
  margin: 0 5px 3px 5px;
}

.data-entry {
  align-items: center;
  padding: 1rem 1rem;
}

.data-entry-item {
  line-height: 1;
  margin-top: auto;
  margin-bottom: 0;
}

.data-entry p {
  font-size: 22px;
}

.header {
  width: '100%';
  height: '100%';
  padding: 1rem 1rem 0.5rem 1rem;
  background-color: #e9ecef;
  border-bottom: 1px solid #787878;
  border-radius: 10px 10px 0 0;
}

.form-group-override {
  margin-bottom: 0;
}

.input-override {
  height: 40px;
  margin-bottom: 0;
}

.prompt {
  margin-bottom: 0;
  white-space: nowrap;
}
.invalid-input {
  width: 100%;
  font-size: 80%;
  text-align: right;
  padding-right: 1rem;
  color: #dc3545;
}
</style>
