<template>
  <div>
    <!-- Province Or State -->
    <ValidationProvider name="province">
      <b-form-group id="input-group-province" :label="label()" label-for="input-province">
        <b-form-select id="input-province" v-model="selectedProvince" :options="provinceOptions"> </b-form-select>
      </b-form-group>
    </ValidationProvider>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { getProvinceStateOptions } from '@/lib/province-options';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';

@Component
export default class ProvinceStateSelect extends Vue {
  @Prop({ required: true, type: String }) country!: string;
  @Prop({ required: false, type: String }) eventProvince!: string;
  @Prop({ required: true, type: Boolean, default: false }) validatePostalCodeLocation!: boolean;

  provinceOptions: { text: string; value: string }[] = [];
  selectedProvince: string | null = null;

  created() {
    this.selectedProvince = this.eventProvince;

    const provinceSelected = getProvinceStateOptions(this.country, this.eventProvince, this.validatePostalCodeLocation);

    this.provinceOptions = provinceSelected.map((provinceState) => {
      return { text: provinceState.name, value: provinceState.code };
    });
  }

  @Watch('country', { immediate: true, deep: true })
  countrySelected(newCountry: string, oldCountry: string) {
    if (newCountry !== oldCountry) {
      const provinceSelected = getProvinceStateOptions(newCountry, this.eventProvince, this.validatePostalCodeLocation);

      this.provinceOptions = provinceSelected.map((provinceState) => {
        return { text: provinceState.name, value: provinceState.code };
      });
    }
  }

  @Watch('selectedProvince', { immediate: true, deep: true })
  provinceChanged() {
    this.provinceInput();
  }

  @Emit('province-input')
  provinceInput() {
    return this.selectedProvince;
  }

  label() {
    return this.country === 'US' ? this.$t('State') : this.$t('Province');
  }
}
</script>

<style>
span .form-group {
  margin-bottom: 2rem;
}
</style>
