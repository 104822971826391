<template>
  <vue-ellipse-progress
    class="progress-text"
    :legend-value="currentStep"
    :color="borderColor"
    :progress="progress"
    :size="50"
  >
    <template #legend-value>
      <span slot="legend-value">/{{ totalSteps }}</span>
    </template>
  </vue-ellipse-progress>
</template>

<script lang="ts">
import { Event } from '@/lib/schema';
import ProjectMixin from '@/project-mixin';

import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class CircleNav extends Mixins(ProjectMixin) {
  event: Event = {} as Event;
  borderColor = '#15803D';
  currentPage = 1;

  get totalSteps() {
    return this.event.childEvents ? this.event.childEvents.length + 2 : 0;
  }

  get currentStep() {
    return this.$store.state.currentPage || 0;
  }

  get progress() {
    return this.totalSteps ? (this.currentStep / this.totalSteps) * 100 : 0;
  }

  mounted() {
    this.event = this.$store.state.event;
    this.borderColor = this.$store.getters.buttonColor.split(' ')[1];
  }
}
</script>
