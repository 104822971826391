<template>
  <ValidationProvider v-slot="caslOptIn" name="confirmation" rules="required">
    <b-form-group id="input-group-casl-opt-in" label-for="input-casl-opt-in">
      <!-- eslint-disable vue/no-mutating-props -->
      <b-form-checkbox
        id="input-casl-opt-in"
        v-model="customer.caslOptIn"
        :state="getValidationState(caslOptIn)"
        aria-describedby="input-casl-opt-in-feedback"
        :unchecked-value="null"
      >
      <!-- eslint-enable vue/no-mutating-props -->
        <i18n path="CASL Confirmation" tag="span">
          <template #privacyPolicy>
            <a :href="privacyPolicyUrl" target="_blank">
              {{ $t('TermsAndPolicy.privacyPolicy') }}
            </a>
          </template>
          <template #termsOfUse>
            <a :href="termsOfUseUrl" target="_blank">
              {{ $t('TermsAndPolicy.termsOfUse') }}
            </a>
          </template>
        </i18n>
      </b-form-checkbox>
      <b-form-invalid-feedback id="input-casl-opt-in-feedback">{{ caslOptIn.errors[0] }}</b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';

interface Flags {
  [k: string]: boolean | null;
}

interface Customer {
  caslOptIn: boolean | null;
}

export default Vue.extend({
  name: 'CASLOptInInput',
  props: {
    customer: {
      type: Object as PropType<Customer>,
      required: true
    }
  },
  computed: {
    privacyPolicyUrl() {
      return this.$i18n.locale === 'fr'
        ? 'https://rafflebox-docs.s3.ca-central-1.amazonaws.com/Rafflebox_Privacy_Policy_FR_01202022.pdf'
        : 'https://rafflebox-docs.s3.ca-central-1.amazonaws.com/Rafflebox+Privacy+Policy+-+US+and+Canada%2C+July+2022.pdf';
    },
    termsOfUseUrl() {
      return this.$i18n.locale === 'fr'
        ? 'https://rafflebox-docs.s3.ca-central-1.amazonaws.com/Rafflebox_Terms_of_Use_FR_01202022.pdf'
        : 'https://rafflebox-docs.s3.ca-central-1.amazonaws.com/Rafflebox+Site+Terms+of+Use+-+July%2C+2022.pdf';
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }: Flags) {
      return dirty || validated ? valid : null;
    }
  }
});
</script>
