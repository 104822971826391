import renderImage from '@/lib/render-image';
import { parseISO } from 'date-fns';
import { formatDateTime, formatPostal, formatPhone, formatCurrency } from '@rafflebox-technologies-inc/rafflebox-lib';

const mixinMethods = {
  formatCurrency: (val: string, params: object) => {
    return formatCurrency(val, params);
  },
  renderImage: renderImage,
  formatDateTime: (val: Date, params: object) => {
    return formatDateTime(val, params);
  },
  pluralize(value: number, text: string) {
    if (value > 1) {
      return value + ' ' + text + 's';
    } else {
      return value + ' ' + text;
    }
  },
  parseISO,
  formatPostal,
  formatPhone
};

export default mixinMethods;
