<template>
  <div></div>
</template>

<script lang="ts">
import Vue from 'vue';
import axios from 'axios';
import { Country, CountryProvinceAgeModel } from '@rafflebox-technologies-inc/rafflebox-schema';

import config from '@/config';

export default Vue.extend({
  name: 'UserLocation',
  props: {
    country: {
      type: String,
      required: true
    },
    eventProvince: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      userLocationProvince: '' as undefined | string,
      isInProvince: false as boolean
    };
  },
  mounted() {
    this.getLocationByIP();
  },
  methods: {
    async getLocationByIP(): Promise<void> {
      // Get Location by IP through ipstack service
      try {
        const protocol = ['test', 'production'].includes(config.ENV) ? 'https://' : 'http://';
        const result = await axios.get(
          `${protocol}api.ipstack.com/check?access_key=${config.IPSTACK_API_TOKEN}&fields=region_code`
        );
        if (!['test', 'production'].includes(config.ENV)) {
          console.log('IP Lookup result', result);
        }

        if (result.status === 200 && result.data.region_code === this.eventProvince) {
          this.isInProvince = true;
          this.$emit('userLocationFound', this.isInProvince);
        } else {
          this.findUserLocation();
        }
      } catch (_error) {
        this.findUserLocation();
      }
    },
    findUserLocation(): void {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.getStreetAddressFrom(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          this.$emit('userLocationFound', false, new Error(`Failed to get latitude/longitude ${error.message}`));
        }
      );
    },
    async getStreetAddressFrom(lat: number, long: number): Promise<void> {
      try {
        const { data } = await axios.get(
          'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
            lat +
            ',' +
            long +
            '&key=AIzaSyC6cA6L9u240P8krxbFToflAbMt2wJd7cw'
        );

        if (!['test', 'production'].includes(config.ENV)) {
          console.log('Street Lookup', data);
        }

        if (data.error_message) {
          this.$emit('userLocationFound', false, new Error(`Failed to get address ${data.error_message}`));
        } else {
          for (const ac of data.results[0].address_components) {
            if (ac.short_name === this.eventProvince) {
              this.isInProvince = true;
              break;
            } else {
              const province = CountryProvinceAgeModel[this.country as Country].provinceState.find(
                (item) => item.code === ac.short_name
              );
              if (province) {
                this.userLocationProvince = province.code;
              }
            }
          }

          this.$emit('userLocationFound', this.isInProvince);
        }
      } catch (error) {
        this.$emit('userLocationFound', false, error);
      }
    }
  }
});
</script>
