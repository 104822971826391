import Axios from '@/lib/axios-api';
import { GoldrushMasterEvent } from '@/lib/schema';

const contextRoot = '/goldrush-service/v2';

const list = async (orgId: string): Promise<GoldrushMasterEvent[]> => {
  const response = await Axios.get(`${contextRoot}/master-events?organizationId=${orgId}`);

  return response.data.data.data as GoldrushMasterEvent[];
};

export default {
  list: list
};
