var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[_c('li',[_c('router-link',{staticClass:"btn btn-step btn-step--one",attrs:{"to":{ path: '/raffle', query: Object.assign({}, _vm.$route.query) },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('button',{class:isActive && 'router-link-active',attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('span',{staticClass:"btn-step__number"},[_vm._v("1")]),_c('span',{staticClass:"btn-step__title"},[_vm._v(_vm._s(_vm.firstTitle))])])]}}])})],1),_c('li',[_c('router-link',{staticClass:"btn btn-step btn-step--two",attrs:{"to":{ path: '/addon', query: Object.assign({}, _vm.$route.query) },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('button',{class:isActive && 'router-link-active',attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('span',{staticClass:"btn-step__number"},[_vm._v("2")]),_c('span',{staticClass:"btn-step__title"},[_vm._v(_vm._s(_vm.secondTitle))])])]}}])})],1),_c('li',[_c('router-link',{staticClass:"btn btn-step btn-step--three",attrs:{"to":{ path: '/confirm', query: Object.assign({}, _vm.$route.query) },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('button',{class:isActive && 'router-link-active',attrs:{"role":"link","disabled":_vm.shoppingCart.totalCents <= 0 ? true : false},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('span',{staticClass:"btn-step__number"},[_vm._v("3")]),_c('span',{staticClass:"btn-step__title"},[_vm._v("Summary")])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }