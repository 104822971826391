export enum CheckoutErrorCodes {
  // Errors from Checkout
  MissingTotalAmountInCart = 'missing-total-amount-in-cart',
  SalesDisabled = 'sales-disabled',
  MissingEventIdParameter = 'missing-event-id-parameter',
  LocationNotFound = 'location-not-found',
  LocationNotInProvince = 'location-not-in-province',
  LocationFoundNotInProvince = 'location-found-not-in-province',
  OrderNotFound = 'order-not-found',
  TicketPdfNotFound = 'orders-without-tickets-found',
  TicketPdfExpired = 'event-draw-date-in-the-past',
  TicketPdfGenericError = 'generic_error'
}

export enum StripeErrorCodes {
  // Stripe Errors
  StripeGenericDecline = 'generic_decline',
  StripeGeneric400 = 'generic_400',
  StripeGeneric500 = 'generic_500',
  StripeIncorrectZip = 'incorrect_zip',
  StripeIncorrectCvc = 'incorrect_cvc',
  StripeExpiredCard = 'expired_card',
  StripePaymentAuthenticationFailure = 'payment_intent_authentication_failure',
  StripeStatementDescriptionTooShort = 'stripe/statement-description-too-short',
  StripeStatementDescriptionMustHaveLetter = 'stripe/statement-description-must-have-letter',
  StripeMissingAccountId = 'stripe-missing-account-id',
  StripeClientSecretMissing = 'stripe-client-secret-missing',
  StripePaymentIntentFailed = 'stripe-payment-intent-failed',
  StripeCardNotSupported = 'stripe-card-not-supported'
}
