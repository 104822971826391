<template>
  <div class="banner">
    <img class="warning-icon" src="../assets/circle-exclamation.svg" />
    <p class="description">Online Ordering Mode: Internal Use Only</p>
  </div>
</template>

<script lang="ts">
export default {
  name: 'CreateOrderActiveBanner'
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff4eb;
  color: black;
  padding: 1rem;
  text-align: center;
  border-radius: 20px;
  margin-bottom: 1.5rem;
}
.description {
  margin: 0;
  color: #525252;
}
.warning-icon {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}
</style>
