<template>
  <div class="age-confirmation">
    <label>
      <p>{{ verificationMessaging() }}</p>
      <input v-model="checkout.verifiedAge" type="checkbox" />
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { getProvinceStateOptions } from '@/lib/province-options';
import ProjectMixin from '@/project-mixin';

@Component
export default class AgeCheckPayment extends Mixins(ProjectMixin) {
  // Determine location and display the appropriate messaging.
  @Prop({ required: true })
  province!: string;
  @Prop({ required: true })
  country!: string;
  @Prop()
  validatePostalCodeLocation!: boolean;
  verificationMessaging() {
    const provinceInfo = getProvinceStateOptions(this.country, this.province).shift();
    let message = '';
    if (provinceInfo) {
      if (this.province === 'ON') {
        message = `${this.$i18n.t('AgeCheckMessages.Ontario', { age: provinceInfo.minimumAge })}`;
      } else if (this.province === 'MB') {
        message = `${this.$i18n.t('AgeCheckMessages.Manitoba', { age: provinceInfo.minimumAge })}`;
      } else if (this.validatePostalCodeLocation && this.country !== 'US') {
        const translationKey = `AgeCheckMessages.${provinceInfo.name}`;
        message = `${this.$i18n.t(translationKey, { age: provinceInfo.minimumAge })}`;
      } else {
        message = `${this.$i18n.t('AgeCheckMessages.generic', { age: provinceInfo.minimumAge })}`;
      }
    }
    return message;
  }
}
</script>
<style lang="scss" scoped>
.age-confirmation {
  display: flex;
  margin: 0 0 -1px;
  padding: 1.25rem 1rem;
  font-family: $body;
  font-size: 1rem;
  border-top: 1px solid $smoke;
  border-bottom: 1px solid $smoke;
  label {
    display: flex;
    margin-bottom: 0;
    cursor: pointer;
    user-select: none;
    p {
      margin: 0.2rem 0 0 1rem;
      order: 2;
    }
    &:hover input ~ .checkmark {
      background-color: $smoke;
    }
    input:checked ~ .checkmark {
      background-color: var(--buttonColor);
    }
    input:checked ~ .checkmark::after {
      display: block;
    }
    .checkmark::after {
      left: 10px;
      top: 5px;
      width: 8px;
      height: 16px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
  input {
    position: absolute;
    height: 0;
    width: 0;
    cursor: pointer;
    opacity: 0;
  }
  .checkmark {
    position: relative;
    display: block;
    flex: 1 0 1.875rem;
    top: 0;
    left: 0;
    height: 1.875rem;
    width: 1.875rem;
    background-color: $white;
    border: 1px solid $slate;
    border-radius: 0.25rem;
    order: 1;
    &::after {
      content: '';
      position: absolute;
      display: none;
    }
  }
}
@media screen and (min-width: 48em) {
  .age-confirmation {
    label {
      font-size: 1.25rem;
      p {
        margin-top: 0;
      }
    }
  }
}
</style>
