<template>
  <div>
    <b-form-group>
      <div id="purchase-details">
        <div v-if="paymentMethod">
          <div class="d-flex mb-3">
            <div class="d-flex flex-column align-items-center mr-2">
              <b-form-radio v-model="payWith" name="pay-with" value="saved">
                <div class="pl-2">
                  <CardIcon :brand="'mastercard'" class="card-icon" />
                  {{
                    $t('Payment.payWithExistingCard', {
                      last4: paymentMethod.last4,
                      expMonth: paymentMethod.expMonth,
                      expYear: paymentMethod.expYear
                    })
                  }}
                </div>
              </b-form-radio>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex">
            <div v-if="paymentMethod" class="d-flex flex-column align-items-center mr-2 mw-">
              <b-form-radio v-model="payWith" name="pay-with" value="new" />
            </div>
            <div class="d-flex flex-column w-100" @click="payWith = 'new'">
              <div>
                <label for="cardNumberElement">{{ $t('Card Number') }}</label>
                <div ref="cardNumberElement" class="form-control"></div>
              </div>
              <div class="d-flex mt-4">
                <div class="d-flex flex-column w-50 mr-5">
                  <label for="cardNumberElement">{{ $t('Expiry Date') }}</label>
                  <div ref="cardExpiryElement" class="form-control"></div>
                </div>
                <div class="d-flex flex-column w-50">
                  <label for="cardNumberElement">{{ $t('CVC') }}</label>
                  <div ref="cardCvcElement" class="form-control"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form-group>
    <ErrorHandler v-if="paymentError" :error="paymentError" />
    <b-form-group class="text-center">
      <RbLoadingButton
        type="submit"
        variant="success"
        class="purchase-button mt-3"
        :loading="purchaseInProgress"
        @click.native="onPurchaseClick()"
      >
        {{ $t('Purchase') }}
        {{
          $i18n.locale === 'fr'
            ? formatCurrency(cartTotal, { precision: 2, locale: 'fr' })
            : formatCurrency(cartTotal, { precision: 2 })
        }}
      </RbLoadingButton>
    </b-form-group>
  </div>
</template>

<script lang="ts">
declare const window: any;
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';

import logger from '@/lib/logger';
import config from '@/config';
import checkout from '@/model/checkout';
import RbLoadingButton from '@/components/RbLoadingButton.vue';
import CardIcon from '@/components/CardIcon.vue';

import PaymentService, {
  StripePaymentIntent,
  StripePaymentIntentResult,
  StripeGoldrushPaymentIntentResult,
  isGoldrushIntent,
  CartItem
} from '@/lib/services/payment-service';
import { ShippingInformationData } from '@/components/ShippingInformation.vue';

import { Customer, Event } from '@/lib/schema';
import { StripeCard } from '@/lib/schema/stripe.schema';
import { StripeErrorCodes } from '@/lib/error-codes';
import ErrorHandler from '@/components/ErrorHandler.vue';

import shoppingCart from '@/model/shopping-cart';
import playerService, { PaymentMethod } from '@/lib/player-service';

let cardNumber: StripeCard;
let cardExpiry: StripeCard;
let cardCvc: StripeCard;

const style = {
  base: {
    color: '#495057',
    fontFamily: '"Muli", sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#6c757d'
    }
  }
};

export default Vue.extend({
  name: 'StripePaymentForm',
  components: { ErrorHandler, RbLoadingButton, CardIcon },
  props: {
    customer: {
      type: Object as () => Customer,
      required: true
    },
    isPlayer: {
      type: Boolean,
      required: false,
      default: false
    },
    formInvalid: {
      type: Boolean,
      required: true
    },
    cart: {
      type: Array,
      required: true
    },
    numDraws: {
      type: Number,
      required: false,
      default: null
    },
    showCreateOnlineOrderButton: {
      type: Boolean,
      required: true
    },
    shippingInformation: {
      type: Object as () => ShippingInformationData,
      required: false,
      default: null
    }
  },
  data: function () {
    return {
      event: this.$store.state.event as Event,
      allowSubmitPayment: false,
      paymentError: false as boolean | string,
      soldOutError: false as boolean,
      purchaseInProgress: false,
      cardType: null as string | null,
      cardNumValid: false,
      cardExpiryValid: false,
      cardCvcValid: false,
      orderType: null,
      payWith: 'new',
      paymentMethod: null as PaymentMethod | null
    };
  },
  computed: {
    ...mapGetters(['returnLink', 'isGoldrush']),
    cartTotal() {
      return shoppingCart.totalCartWithProcessingFee();
    }
  },
  watch: {
    // watch for player login
    isPlayer: function (newPlayer, oldPlayer) {
      if (newPlayer && newPlayer != oldPlayer) {
        this.loadPaymentMethod();
      }
    }
  },
  async mounted() {
    try {
      logger.trace('Loading stripe payment form', { event: this.event, customer: this.customer });

      const elements = this.$store.state.stripe.elements();
      // Create an instance of the card Element.
      cardNumber = elements.create('cardNumber', {
        style: style,
        classes: { complete: 'is-valid', invalid: 'is-invalid' },
        showIcon: true
      });

      cardNumber.on('change', () => {
        if (this.payWith != 'new') {
          this.payWith = 'new';
        }
      });

      cardExpiry = elements.create('cardExpiry', {
        style: style,
        classes: { complete: 'is-valid', invalid: 'is-invalid' }
      });
      cardCvc = elements.create('cardCvc', {
        style: style,
        classes: { complete: 'is-valid', invalid: 'is-invalid' }
      });

      // Add an instance of the card Element into the `card-element` <div>.
      cardNumber.mount(this.$refs.cardNumberElement);
      cardExpiry.mount(this.$refs.cardExpiryElement);
      cardCvc.mount(this.$refs.cardCvcElement);

      cardNumber.on('change', ({ error, complete, brand }: any) => {
        if (error) {
          this.allowSubmitPayment = false;
        } else {
          if (complete) {
            this.cardType = brand;
            this.cardNumValid = true;
          }
        }
      });

      cardExpiry.on('change', ({ error, complete }: any) => {
        if (error) {
          this.allowSubmitPayment = false;
        } else {
          if (complete) {
            this.cardExpiryValid = true;
          }
        }
      });

      cardCvc.on('change', ({ error, complete }: any) => {
        if (error) {
          this.allowSubmitPayment = false;
        } else {
          if (complete) {
            this.cardCvcValid = true;
          }
        }
      });

      if (this.cardNumValid && this.cardExpiryValid && this.cardCvcValid) {
        this.allowSubmitPayment = true;
        logger.trace('Stripe payment form successfully loaded', {});
      } else {
        this.allowSubmitPayment = false;
      }

      await this.loadPaymentMethod();
    } catch (error: any) {
      if (error.response && error.response.status !== 400) {
        logger.info(`Loading stripe payment form failed - ${error.message}`, {
          eventId: this.event.id,
          customer: this.customer,
          error: error.response ? JSON.stringify(error.response.data) : error
        });
      }

      this.$emit('stripe-intent-error', `Loading stripe payment form failed - ${error.message}`);
    }
  },
  methods: {
    ...mapActions(['setCustomer']),
    isStripeFormElementEmpty() {
      // This checks for the --empty flag on the containers for the Stripe elements.
      // Since Stripe only provides error checkiing 'on change' this is required to check if the
      // user has left the fields empty.
      // https://github.com/stripe-archive/react-stripe-elements/issues/283
      if (this.$refs.cardNumberElement && this.$refs.cardExpiryElement && this.$refs.cardCvcElement) {
        // @ts-expect-error Grandfathered
        const cardNumberEmpty = this.$refs.cardNumberElement.classList.contains('StripeElement--empty');
        // @ts-expect-error Grandfathered
        const cardExpiryEmpty = this.$refs.cardExpiryElement.classList.contains('StripeElement--empty');
        // @ts-expect-error Grandfathered
        const cardCvcEmpty = this.$refs.cardCvcElement.classList.contains('StripeElement--empty');

        return cardNumberEmpty || cardExpiryEmpty || cardCvcEmpty;
      }
    },
    async loadPaymentMethod() {
      if (this.isPlayer) {
        const paymentMethods = await playerService.listPaymentMethods();

        const paymentMethod = paymentMethods[0];

        if (paymentMethod) {
          this.paymentMethod = paymentMethod;
          this.payWith = 'saved';
        }
      }
    },
    // Bypass regular purchase click and create the payment intent using the saved card
    async payWithSavedMethod() {
      if (this.formInvalid) {
        // Form Errors
        this.$emit('error-found');

        return;
      }

      this.cardType = this.paymentMethod?.brand ?? null;

      await this.createStripePaymentIntent(true);
    },
    onPurchaseClick() {
      if (this.purchaseInProgress) {
        return;
      }

      if (this.payWith === 'saved') {
        this.payWithSavedMethod();
        return;
      }

      this.setCustomer(this.customer);

      this.paymentError = '';

      if (this.isStripeFormElementEmpty()) {
        this.allowSubmitPayment = false;
        // Stripe form is empty or partially empty.
        this.paymentError = 'Please ensure to enter your credit card number, expiry date and CVC';
      }

      // If there are any issues on the parent Payment component
      if (this.formInvalid) {
        // Form Errors
        this.$emit('error-found');
      }

      // If there are any errors reported by the stripe elements
      if (!this.allowSubmitPayment) {
        // Stripe Payment Form Errors (onChange triggered)
        if (!this.cardNumValid || !this.cardExpiryValid || !this.cardCvcValid) {
          this.allowSubmitPayment = false;
          this.paymentError = 'Please ensure to enter your credit card number, expiry date and CVC';
        } else {
          if (!this.isStripeFormElementEmpty()) {
            this.allowSubmitPayment = true;
          }
        }
      }

      if (!this.formInvalid && this.allowSubmitPayment) {
        this.createStripePaymentIntent();
      }
    },
    createStripePaymentIntent: _.debounce(async function (this: any, useSavedMethod = false) {
      this.purchaseInProgress = true;

      try {
        this.paymentError = '';

        const cleanPhone = this.customer.phone.replace(/\D/g, '');
        const cleanPostal = this.customer.postal.replace(' ', '');

        const paymentIntent: StripePaymentIntent = {
          eventId: this.event.id,
          email: this.customer.email.toLowerCase(),
          firstName: this.customer.firstName,
          lastName: this.customer.lastName,
          secondaryName: this.customer.secondaryName,
          address: this.customer.address,
          city: this.customer.city,
          province: this.customer.province,
          cardType: this.cardType,
          country: this.customer.country ? this.customer.country : this.event.country,
          postal: cleanPostal,
          phone: cleanPhone,
          eventMemberNumber:
            checkout.selectedEventMember && checkout.selectedEventMember !== -1
              ? checkout.selectedEventMember.toString()
              : undefined,
          campaignId: this.$route.query.cid,
          paymentRequestButton: false,
          cartItems: this.cart as CartItem[],
          caslOptIn: this.customer.caslOptIn ?? false,
          age: this.customer.age,
          title: this.customer.title
        };

        if (shoppingCart.totalDonationCents() > 0 && !this.isGoldrush) {
          paymentIntent.donationAmountCents = shoppingCart.totalDonationCents();
        }

        if (!this.isGoldrush) {
          paymentIntent.shippingAddressLine1 = this.shippingInformation.address;
          paymentIntent.shippingCity = this.shippingInformation.city;
          paymentIntent.shippingState = this.shippingInformation.province ? this.shippingInformation.province : null;
          paymentIntent.shippingPostal = this.shippingInformation.postal ? this.shippingInformation.postal : null;
        }

        if (this.$store.state.showCreateOnlineOrderButton) {
          this.orderType = 'phone';

          paymentIntent.orderType = this.orderType;
        }

        if (!this.isGoldrush) {
          paymentIntent.locale = this.$i18n.locale;
        } else {
          paymentIntent.locale = undefined;
        }

        if (this.isGoldrush) {
          paymentIntent.numDraws = this.numDraws;
        }

        logger.trace('Stripe create payment intent request', { paymentIntent });

        let paymentIntentResult;
        if (this.isGoldrush) {
          paymentIntentResult = await PaymentService.createStripePaymentIntentGoldrush(paymentIntent);
        } else {
          paymentIntentResult = await PaymentService.createStripePaymentIntent(paymentIntent);
        }

        logger.trace('Stripe create payment intent successful', { paymentIntentResult });

        this.confirmStripePayment(paymentIntentResult, paymentIntent, useSavedMethod);
      } catch (error: any) {
        logger.info(`Stripe payment intent failed - ${error.message}`, {
          email: this.customer ? this.customer.email : undefined,
          customer: this.customer,
          eventId: this.event.id,
          error: error.response ? JSON.stringify(error.response.data) : error
        });

        // Stripe Payment intent errors will result in the form being disabled
        // Requiring the user to contact support or start again depending on error.

        this.purchaseInProgress = false;
        this.$emit('disable-spinner');

        if (error.response && error.response.data.errors.length > 0) {
          let returnedError = error.response.data.errors[0].message;

          if (Array.isArray(returnedError)) {
            returnedError = returnedError[0].message;
          }

          this.$emit('stripe-intent-error', returnedError);
        } else {
          this.paymentError = error;
        }
      }
    }, 600),
    async confirmStripePayment(
      paymentIntentResult: StripePaymentIntentResult | StripeGoldrushPaymentIntentResult,
      paymentIntent: StripePaymentIntent,
      useSavedMethod: boolean
    ) {
      const billingDetails = {
        name: `${paymentIntent.firstName} ${paymentIntent.lastName}`,
        email: paymentIntent.email.toLowerCase(),
        phone: paymentIntent.phone,
        address: {
          city: paymentIntent.city,
          country: paymentIntent.country,
          line1: paymentIntent.address,
          postal_code: paymentIntent.postal,
          state: paymentIntent.province
        }
      };

      let confirmPaymentResult;

      try {
        confirmPaymentResult = await this.$store.state.stripe.confirmCardPayment(
          paymentIntentResult.stripeClientSecret,

          {
            payment_method:
              useSavedMethod && this.paymentMethod
                ? this.paymentMethod.id
                : {
                    card: cardNumber,
                    billing_details: billingDetails
                  }
          },
          {
            handleActions: true
          }
        );

        logger.trace('Confirm Payment Result', { confirmPaymentResult });

        if (confirmPaymentResult.paymentIntent && confirmPaymentResult.paymentIntent.status === 'succeeded') {
          if (['test', 'production'].includes(config.ENV)) {
            // add facebook pixel tracking event
            window.fbq('track', 'Purchase', {
              value: this.cartTotal,
              currency: this.$store.state.event.currency.toUpperCase(),
              content_name: this.$store.state.event.shortlink.toLowerCase()
            });

            // Add purchase to GA
            if (this.$gtm.enabled()) {
              // Build array of current cart
              const products = shoppingCart.items().map((tp) => {
                const price = tp.priceCents / 100;

                return {
                  // Linter complains about non-camelCase
                  /* eslint-disable */
                  item_id: tp.id,
                  item_name: this.$store.state.event.name,
                  price: price,
                  item_category: this.$store.state.event.category,
                  quantity: tp.quantity,
                  item_variant: `${tp.numTickets} for $${price}`,
                  index: 0
                  /* eslint-enable */
                };
              });

              const totalPurchased = shoppingCart.totalCart();

              (window as any).dataLayer.push({
                event: 'purchase',
                ecommerce: {
                  transaction_id: confirmPaymentResult.paymentIntent.id,
                  currency: this.$store.state.event.currency.toUpperCase(),
                  value: totalPurchased,
                  items: products
                }
              });
            }
          }

          // Add paymentIntent to store for display usage on success page
          await this.$store.dispatch('updatePaymentIntent', paymentIntent).then(() => {
            const orders = paymentIntentResult.orders.map((order) => {
              return {
                eventId: order.eventId,
                orderId: order.id.substr(0, 8).toUpperCase(),
                orderIdLong: order.id
              };
            });

            const params: { [k: string]: any } = {
              orders: JSON.stringify(orders)
            };

            if (isGoldrushIntent(paymentIntentResult)) {
              params.numDraws = paymentIntentResult.numDraws;
              params.lastDrawDate = paymentIntentResult.lastDrawDate;
            }

            if (this.showCreateOnlineOrderButton) {
              params.showCreateOnlineOrderButton = true;
              params.fullPath = this.$route.fullPath;
            }

            // Route to new page and send success information
            this.$router.push({
              name: 'Success',
              params: params
            });
          });
        } else {
          // If anything comes back other than succeeded, present generic error.
          this.paymentError = StripeErrorCodes.StripeCardNotSupported;
          logger.info(`Stripe payment failed - 3D secure`, {
            email: this.customer ? this.customer.email : undefined,
            customer: this.customer,
            eventId: this.event.id,
            confirmPaymentResult: confirmPaymentResult.paymentIntent
          });
        }
      } catch (error: any) {
        // Stripe.confirmCardPayment failed
        logger.info(`Stripe confirm payment failed - ${error.message}`, {
          email: this.customer ? this.customer.email : undefined,
          customer: this.customer,
          eventId: this.event.id,
          error
        });

        if (error.response && error.response.status === 400) {
          // Generic 400 error
          this.paymentError = StripeErrorCodes.StripeGeneric400;
        } else if (error.response && error.response.status == 500) {
          // Generic 500 error
          this.paymentError = StripeErrorCodes.StripeGeneric500;
        }
      }

      this.purchaseInProgress = false;

      if (confirmPaymentResult && confirmPaymentResult.error) {
        const errorCode = confirmPaymentResult.error.code;
        const declineCode = confirmPaymentResult.error.decline_code || 'Unknown';
        const errorMessage = confirmPaymentResult.error.message;

        // If there is a decline_code, only display generic message
        // But log the actual message
        if (declineCode !== 'Unknown') {
          this.paymentError = StripeErrorCodes.StripeGenericDecline;

          logger.info(`Stripe payment declined - ${declineCode} - ${errorMessage}`, {
            email: this.customer ? this.customer.email : undefined,
            customer: this.customer,
            eventId: this.event.id,
            error: confirmPaymentResult.error
          });
        } else {
          // Else pass error to errorHandler.
          this.paymentError = errorCode;
          logger.info(`Stripe payment declined - ${errorCode} - ${errorMessage}`, {
            email: this.customer ? this.customer.email : undefined,
            customer: this.customer,
            eventId: this.event.id,
            error: confirmPaymentResult.error
          });
        }

        this.$emit('disable-spinner');
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.StripeElement {
  box-sizing: border-box;
  height: calc(1.5em + 1.2rem + 2px);
  padding: 0.8rem 1rem 0.6rem;
  border-radius: 0.5rem;
  background-color: $white;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.purchase-button {
  font-size: 20px;
  min-width: 12.5rem;
  background-color: var(--buttonColor);
  border-color: var(--buttonColor);

  &:active,
  &:hover {
    color: var(--buttonColor) !important;
    background-color: $white !important;
    border-color: var(--buttonColor) !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.alert-link {
  padding-left: 0.2rem;
  color: $dangerRed;
}

.card-icon {
  display: inline-block;
  width: 2.5em;
}
</style>
