<template>
  <div>
    <ValidationProvider v-slot="errors" :name="`${name}`" :custom-messages="dict" :rules="rules">
      <b-form-group id="input-group-postal" :label="label()" :label-for="`input-${name}`">
        <b-form-input
          id="input-postal"
          v-model="postalCode"
          v-mask="mask"
          :state="getValidationState(errors)"
          :type="type"
        ></b-form-input>
        <b-form-invalid-feedback id="input-postal-feedback">{{ errors.errors[0] }}</b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
  </div>
</template>
<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

interface Flags {
  [k: string]: boolean | null;
}

@Component
export default class PostalZipInput extends Vue {
  @Prop({ required: true, type: String }) country!: string;
  @Prop({ required: false, type: String }) province!: string;
  @Prop({ required: true, type: Boolean, default: false }) validatePostalCodeLocation!: boolean;
  @Prop({ required: true, type: String }) parentName!: string;
  @Prop({ required: false, type: String }) value!: string;

  name = '';
  type = '';
  rules = '';
  mask = '';
  dict = {};

  @Watch('country', { immediate: true, deep: true })
  countrySelected(newCountry: string) {
    if (newCountry === 'CA') {
      this.name = 'postal';
      this.type = 'text';
      this.mask = 'A#A #A#';
      this.rules =
        this.parentName === 'shipping'
          ? this.validatePostalCodeLocation
            ? `required|max:7|shippingPostal:${this.province}`
            : `required|max:7`
          : this.validatePostalCodeLocation
            ? `required|max:7|postal:${this.province}`
            : `required|max:7`;
      this.dict = {
        min: 'The postal field must be at least 7 characters'
      };
    } else if (newCountry === 'US') {
      this.name = 'zipCode';
      this.type = 'text';
      this.rules = 'required|digits:5';
      this.mask = '#####';
      this.dict = {
        min: 'Zip code must be 5 digits'
      };
    }
  }

  @Watch('province', { immediate: true, deep: true })
  provinceUpdated(newProvince: string) {
    this.province = newProvince;
    this.rules =
      this.parentName === 'shipping'
        ? this.validatePostalCodeLocation
          ? `required|max:7|shippingPostal:${this.province}`
          : `required|max:7`
        : this.validatePostalCodeLocation
          ? `required|max:7|postal:${this.province}`
          : `required|max:7`;
  }

  label() {
    return this.country === 'US' ? this.$t('Zip Code') : this.$t('Postal Code');
  }
  getValidationState({ dirty, validated, valid = null }: Flags) {
    return dirty || validated ? valid : null;
  }

  get postalCode() {
    return this.value;
  }

  set postalCode(value) {
    this.$emit('input', value);
  }
}
</script>
