import { initializeAuth, getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import type { AxiosInstance } from 'axios';
import config from '@/config';
import { axios } from '@/lib/axios-player-portal';

type Environment = 'production' | 'development' | 'test' | 'testPlayerPortal' | 'productionPlayerPortal';

const env = config.PLAYER_PORTAL_ENV as Environment;

// Initialize the auth service
initializeAuth(env, axios as AxiosInstance);

// Get the auth instance
export const auth = getAuth();
