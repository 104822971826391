<template>
  <div class="stripe-intent-error">
    <RbAlert v-if="!displayFallback" variant="danger" icon="exclamation-circle">
      <span v-html="errorMessage" />
    </RbAlert>
    <RbAlert v-else variant="danger" icon="exclamation-circle">
      <p>Oops, something went wrong.</p>
      <pre>Error - {{ errorMessage }}</pre>
      <a href="https://support.rafflebox.ca/hc/en-us/requests/new" target="_blank"
        >If you have questions, please contact support.</a
      >
    </RbAlert>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator';
import ProjectMixin from '@/project-mixin';
import { ErrorCodes } from '@rafflebox-technologies-inc/rafflebox-schema';
import { CheckoutErrorCodes, StripeErrorCodes } from '@/lib/error-codes';
import RbAlert from '@/components/RbAlert.vue';

@Component({
  components: {
    RbAlert
  }
})
export default class ErrorMessage extends Mixins(ProjectMixin) {
  @Prop({ required: true, default: '' })
  error!: ErrorCodes | StripeErrorCodes | CheckoutErrorCodes;

  displayFallback = false;

  get goldrushSignupLink() {
    return this.$store.getters.goldrushSignupLink;
  }

  get returnLink() {
    return this.$store.getters.returnLink;
  }

  get errorMessage() {
    const getErrorMessage = this.errorResponses.get(this.error);

    if (typeof getErrorMessage === 'undefined') {
      this.displayFallback = true;
      const errorCodeHuman = this.error.charAt(0).toUpperCase() + this.error.slice(1);
      return errorCodeHuman.replace(/[-_]/g, ' ');
    }

    return getErrorMessage;
  }

  errorResponses = new Map<ErrorCodes | StripeErrorCodes | CheckoutErrorCodes, string>([
    [
      ErrorCodes.GoldrushCustomerNameDoesNotMatch,
      'The name you entered does not match the name on your Goldrush account. Please try again.'
    ],
    [
      ErrorCodes.CustomerNotFound,
      `We could not find you in the system. If you are new to Goldrush Online, <a href="${this.goldrushSignupLink}">Click here to signup.</a>. If you have signed up you will need to activate your account before making a purchase or <a href="https://support.rafflebox.ca/hc/en-us/requests/new" target="_blank">contact support</a>.`
    ],
    [
      ErrorCodes.CustomerNotActive,
      'It appears your account has not been activated or has been disabled. Please activate your account from the email that was sent during Signup or <a href="https://support.rafflebox.ca/hc/en-us/requests/new" target="_blank">contact support</a>.'
    ],
    [
      ErrorCodes.CustomerNotSignedUp,
      `We see that you are not signed up for Goldrush Online. <a href="${this.goldrushSignupLink}">Click here to sign up!</a>. If you have signed up you will need to activate your account from the email that was sent to you before making a purchase or <a href="https://support.rafflebox.ca/hc/en-us/requests/new" target="_blank">contact support</a>.`
    ],
    [
      ErrorCodes.GoldrushCustomerMissingPlayerNumber,
      `It appears your account wasn't activated or there was an error creating your account. <a href="https://support.rafflebox.ca/hc/en-us/requests/new" target="_blank">please contact support</a> for more information.`
    ],
    [
      CheckoutErrorCodes.MissingTotalAmountInCart,
      `There was an error with your cart. <a href="/${this.returnLink}?eventId=${this.$store.state.event.id}"> Please try again </a>`
    ],
    [
      ErrorCodes.CustomerPhoneDoesNotMatchEmail,
      `The phone number you entered does not match the email when you signed up. If your phone number has changed <a href="https://support.rafflebox.ca/hc/en-us/requests/new" target="_blank">please contact support</a> for more information.`
    ],
    [
      ErrorCodes.TicketPackageSoldOut,
      `One or more of your selected tickets are sold out. <a href="/${this.returnLink}?eventId=${this.$store.state.event.id}"> Please try again </a>`
    ],
    [
      ErrorCodes.EventMaxJackpot,
      `One of the events has reached its max jackpot limit. <a href="/${this.returnLink}?eventId=${this.$store.state.event.id}"> Please try again </a>`
    ],
    [
      ErrorCodes.MaxOrdersPerCustomerReached,
      `We're sorry, the raffle administrator has placed a limit on purchases per person for this raffle.`
    ],
    [
      StripeErrorCodes.StripeIncorrectZip,
      'The billing address or postal code you have entered does not match with what is registered with your credit card company. A charge may appear on your bank statement but this will be reversed by your credit card company.'
    ],
    [
      StripeErrorCodes.StripeGeneric500,
      'Payment failed - We are experiencing intermittent issues. Your credit card will NOT be charged. Please try again in a few minutes.'
    ],
    [
      StripeErrorCodes.StripeCardNotSupported,
      'Your credit card company requires 3D Secure verification. Unfortunately, we do not support this process. <br />Please try a different card.'
    ],
    [ErrorCodes.PostalCodeNotInProvince, 'The postal code must be from within the province of the raffle.'],
    [ErrorCodes.ZipCodeNotInState, 'The ZIP code must be from within the state of the raffle.'],
    [StripeErrorCodes.StripeGeneric400, 'Please ensure all fields above are entered correctly.'],
    [StripeErrorCodes.StripeIncorrectCvc, 'The security code you entered was declined by your credit card company.'],
    [StripeErrorCodes.StripeGenericDecline, 'Your credit card company declined the payment.'],
    [StripeErrorCodes.StripeExpiredCard, 'The credit card is expired.'],
    [
      StripeErrorCodes.StripePaymentAuthenticationFailure,
      'The provided payment method has failed authentication. Please provide a new payment method'
    ],
    [
      CheckoutErrorCodes.LocationNotFound,
      `To purchase tickets, you must be physically located in the permitted sales region of the raffle. Make sure you enable Location Services on your device so it can determine which state/province you are located in. <br><a href="https://support.rafflebox.ca/hc/en-us/requests/new" target="_blank">If you have questions, please contact support.</a>`
    ],
    [
      CheckoutErrorCodes.LocationNotInProvince,
      `We're sorry! It appears you are not located in the permitted sales region of the raffle. Only people physically located in the permitted sales region can buy tickets. <br><a href="https://support.rafflebox.ca/hc/en-us/requests/new" target="_blank">If you have questions, please contact support.</a>`
    ],
    [
      CheckoutErrorCodes.LocationFoundNotInProvince,
      `It appears you are outside the permitted sales region. Only people within ${this.$store.state.location.required} can buy tickets for this raffle <br><a href="https://support.rafflebox.ca/hc/en-us/requests/new" target="_blank">If you have questions, please contact support.</a>`
    ],
    [
      CheckoutErrorCodes.OrderNotFound,
      `There was an error retrieving the PDF of your ticket numbers. Please check your email address for your ticket numbers and purchase receipt.`
    ],
    [
      CheckoutErrorCodes.TicketPdfNotFound,
      `There was an error retrieving the PDF of your ticket numbers. Please check your email address for your ticket numbers and purchase receipt.`
    ],
    [
      CheckoutErrorCodes.TicketPdfExpired,
      `Your PDF has expired. Ticket PDFs are only held for 7 days after purchase. Please <a href="https://support.rafflebox.ca/hc/en-us/requests/new" target="_blank">contact support.</a>`
    ],
    [CheckoutErrorCodes.TicketPdfGenericError, `Something went wrong. Please try again in a few minutes.`]
  ]);
}
</script>

<style lang="scss">
.stripe-intent-error {
  margin-top: 2rem;

  a {
    font-weight: bold;
    color: $dangerRed;

    &:hover,
    &:focus {
      color: $dangerRed;
    }
  }

  p,
  pre {
    margin-bottom: 0.5rem;
    white-space: normal;
  }
}
</style>
