<template>
  <h3 v-if="showInSupport" class="in-support-of mb-4">{{ $t(inSupportLabel) }} {{ inSupportName }}</h3>
</template>

<script lang="ts">
import ProjectMixin from '@/project-mixin';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class InSupport extends Mixins(ProjectMixin) {
  get showInSupport() {
    return this.$store.state.event.supportStatsActive && this.checkout.selectedEventMemberName !== '';
  }

  get inSupportLabel() {
    return this.$store.state.event.supportStatsLabel;
  }

  get inSupportName() {
    return this.checkout.selectedEventMemberName;
  }
}
</script>
