import axios from '@/lib/axios-payment-api';

const contextRoot = '/payment-service/v2';

export interface OrderObject {
  id: string;
  eventId: string;
  email: string;
  provinceState: string;
  numTickets: number;
  amountPaid: number;
  paid: boolean;
  phone: string;
  name: string;
  // TODO use enum
  status: string;
}
export interface CartItem {
  numPackages: number;
  ticketPackageId: string;
}
export interface StripePaymentIntent {
  eventId: string;
  eventMemberNumber: string | undefined;
  email: string;
  firstName: string;
  lastName: string;
  secondaryName?: string;
  address: string;
  city: string;
  province: string;
  country: string;
  postal: string;
  phone: string;
  cardType: string;
  campaignId?: string;
  paymentRequestButton?: boolean;
  cartItems: CartItem[];
  numDraws?: number;
  locale?: string;
  caslOptIn?: boolean;
  orderType?: string | null;
  donationAmountCents?: number;
  shippingAddressLine1?: string | null;
  shippingCity?: string | null;
  shippingState?: string | null;
  shippingPostal?: string | null;
  age?: string | null;
  title?: string | null;
}
export interface StripePaymentIntentResult {
  orders: OrderObject[];
  stripeClientSecret: string;
}

export interface StripeGoldrushPaymentIntentResult extends StripePaymentIntentResult {
  amountPaidCents: number;
  drawFrom: number;
  drawTo: number;
  numDraws: number;
  subscriptionId: string;
  firstDrawDate: Date;
  lastDrawDate: Date;
}

// Type Gaurd
export const isGoldrushIntent = (
  intent: StripePaymentIntentResult | StripeGoldrushPaymentIntentResult
): intent is StripeGoldrushPaymentIntentResult => {
  return (intent as StripeGoldrushPaymentIntentResult).numDraws !== undefined;
};

const createStripePaymentIntent = async (paymentIntent: StripePaymentIntent): Promise<StripePaymentIntentResult> => {
  const response = await axios.post(`${contextRoot}/stripe`, paymentIntent);

  const result = response.data.data as StripePaymentIntentResult;

  return result;
};

const createStripePaymentIntentGoldrush = async (
  paymentIntent: StripePaymentIntent
): Promise<StripeGoldrushPaymentIntentResult> => {
  const response = await axios.post(`${contextRoot}/stripe/goldrush`, paymentIntent);

  return response.data.data as StripeGoldrushPaymentIntentResult;
};

export default {
  createStripePaymentIntent: createStripePaymentIntent,
  createStripePaymentIntentGoldrush: createStripePaymentIntentGoldrush,
  isGoldrushIntent: isGoldrushIntent
};
