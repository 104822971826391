import { datadogRum } from '@datadog/browser-rum';

import config from '@/config';

if (['test', 'production'].includes(config.ENVIRONMENT)) {
  datadogRum.init({
    applicationId: '1deaf276-b787-4def-ac8b-19577abd1015',
    clientToken: 'pub0b2ba935cecdb2e2f972a9478e6505b6',
    site: 'datadoghq.com',
    service: 'checkout',
    env: config.ENVIRONMENT,
    version: config.GIT_COMMIT,
    sampleRate: config.ENVIRONMENT === 'production' ? 10 : 100,
    // Must be set explicitly to avoid a charge for Datadog RUM Session Replay
    replaySampleRate: 0,
    trackInteractions: true,
    allowedTracingOrigins: [config.PAYMENT_API_URL]
  });
}

class Logger {
  info = (message: string, extra?: any) => {
    console.log(message, JSON.stringify(extra));
  };

  error = (message: string, extra: any) => {
    const error = new Error(message);
    datadogRum.addError(error, extra);
  };

  debug = (message: string) => {
    console.log(message);
  };

  trace = (message: string, data: any) => {
    datadogRum.addAction(message, data);
  };
}

const logger = new Logger();

export default logger;
