// https://docs.getunleash.io/reference/sdks/javascript-browser
import { UnleashClient, IMutableContext } from 'unleash-proxy-client';
import config from '../config';

const unleashConfig = {
  url: 'https://us.app.unleash-hosted.com/usff0003/api/frontend',
  clientKey: config.UNLEASH_CLIENT_KEY,
  appName: 'checkout',
  refreshInterval: 0 // never refresh without reload of page
};

let client: UnleashClient;

/**
 * All keys in Unleash would be the values of this object only.
 */
export const UnleashKeys = {
  Phase1: 'Phase-1',
  Phase2: 'Phase-2',
  Phase3: 'Phase-3',
  PreviewTickets: 'PreviewTickets'
};

const refreshClient = async () => {
  await client.stop();
  await client.start();
  console.log('[Unleash] Client restarted and flags fetched with updated context');
};

export const initializeUnleash = async (context?: IMutableContext): Promise<void> => {
  console.log('[Unleash] Initializing Unleash client');
  client = new UnleashClient(unleashConfig);
  client.start();

  return new Promise((resolve) => {
    client.on('ready', () => {
      // Required to prevent a race check where the user context is not yet set on refresh
      if (context) {
        client.updateContext(context);
      }

      // eslint-disable-next-line
      console.log('[Unleash] Client is ready');
      resolve();
    });
  });
};

export const updateUnleashContext = async (context?: any) => {
  if (!client) {
    // eslint-disable-next-line
    console.warn('[Unleash] Client is not initialized');
    return;
  }

  if (!context) {
    return;
  }

  client.updateContext(context);
  await refreshClient();
  console.log('[Unleash] Updated context');
};

export const unleashFeatureEnabled = (key: string): boolean => {
  if (!client) {
    // eslint-disable-next-line
    console.warn('[Unleash] Client is not initialized');
    return false;
  }

  const isEnabled: boolean = client.isEnabled(key);

  return isEnabled;
};
