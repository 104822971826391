<template>
  <div class="button-container">
    <b-button variant="outline-success" @click="redirectToPreviewTickets">
      {{ $t('Success.previewTickets') }}
    </b-button>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import _ from 'lodash';
import config from '@/config';

export default Vue.extend({
  name: 'PreviewTicketsButton',
  data() {
    return {
      siteUrl: config.RAFFLEBOX_URL || 'https://rafflebox.ca',
    }
  },
  methods: {
    redirectToPreviewTickets() {
      const parentRaffleOrderId = this.$route.params.orders
        ? JSON.parse(this.$route.params.orders)[0]?.orderIdLong
        : null;

      // Check the locale
      const locale = this.$i18n.locale;

      // Adjust URL based on locale
      const url = locale === 'fr'
        ? `${this.siteUrl}/fr/order/${parentRaffleOrderId}`
        : `${this.siteUrl}/order/${parentRaffleOrderId}`;

      window.open(url);
    }
  },
});
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: center;
}
</style>
