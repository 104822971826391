import axios from '@/lib/axios-api';
import config from '@/config';

const baseUrl = config.TICKET_API_URL;
const contextRoot = '/download';

const getDownloadTicketsUrl = async (params = {}) => {
  const response = await axios.get(contextRoot, { params: params, baseURL: baseUrl });

  return response.data.data;
};

export default {
  getDownloadTicketsUrl: getDownloadTicketsUrl
};
