<template>
  <header v-if="showBannerOnSuccess" class="header" :class="{ 'custom-header': displayCustomBanner }">
    <b-container fluid="md">
      <b-row>
        <b-col lg="8" offset-lg="2" class="col-xxl-6 offset-xxl-3">
          <div
            :class="[
              'd-flex justify-content-between align-items-center',
              { 'justify-content-end': displayCustomBanner }
            ]"
          >
            <img
              v-if="!displayCustomBanner"
              src="https://images.rafflebox.ca/RaffleboxLogos/wordmark-green.svg"
              alt=""
              class="logo"
            />
            <!-- Login Modal Component -->
            <LoginModal :title="'Login'" :show-forgot-password="true">
              <template #logo> <img class="login-logo" src="@/assets/rafflebox.png" alt="rafflebox logo" /> </template
            ></LoginModal>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="displayCustomBanner" class="mt-4">
        <b-col lg="8" offset-lg="2" class="col-xxl-6 offset-xxl-3">
          <img :src="customLogoUrl" alt="" class="custom-logo" />
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import RbLoadingButton from '@/components/RbLoadingButton.vue';
import LoginModal from '@/components/LoginModal.vue';

@Component({ components: { RbLoadingButton, LoginModal } })
export default class AppNav extends Vue {
  showBannerOnSuccess = true;
  phase1 = false;
  showModal = false; // Add modalShow to control the modal visibility

  get displayCustomBanner() {
    return this.$store.getters.displayCustomBanner;
  }

  get customLogoUrl() {
    if (this.$route.name === 'Addon') {
      return this.$store.state.addon.logoUrl;
    } else {
      return this.$store.state.event.logoUrl;
    }
  }

  get buttonColor() {
    return this.$store.getters.buttonColor.split(' ')[1];
  }

  get event() {
    return this.$store.state.event;
  }

  get deviceSerialNumber() {
    return this.$store.state.deviceSerialNumber;
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    if (this.$route.name === 'Success' && this.displayCustomBanner) {
      this.showBannerOnSuccess = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 1rem;
  padding: 1rem;
  text-align: center;
  border-bottom: 1px solid $slate;
}

.logo {
  width: 12.5rem;
}

.custom-header {
  margin-top: 2rem;
  padding: 0;
  border-bottom: none;
}

.custom-logo {
  width: 100%;
  margin-top: 1rem;
}

.login-logo {
  width: 50%;
}

.login-button {
  background-color: var(--buttonColor);
  border-color: var(--buttonColor);

  &:hover {
    color: var(--buttonColor) !important;
    background-color: $white !important;
    border-color: var(--buttonColor) !important;
  }
}

.justify-content-end {
  justify-content: flex-end !important;
}

@media screen and (min-width: 48em) {
  .logo {
    width: 18.75rem;
  }

  .custom-header {
    margin-top: 2rem;
    padding: 1rem;
    border-bottom: none;
  }
}
</style>
