<template>
  <div>
    <p class="mb-4 text-center">
      {{ $t('TermsAndPolicy.prependMessage') }}
      <a :href="setPrivacyPolicyLang" target="_blank"> {{ $t('TermsAndPolicy.privacyPolicy') }} </a>
      {{ $t('and') }}
      <a :href="setTermsOfUseByLang" target="_blank"> {{ $t('TermsAndPolicy.termsOfUse') }} </a>.
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    setPrivacyPolicyLang() {
      return this.$i18n.locale === 'fr'
        ? 'https://rafflebox-docs.s3.ca-central-1.amazonaws.com/Rafflebox_Privacy_Policy_FR_01202022.pdf'
        : 'https://rafflebox-docs.s3.ca-central-1.amazonaws.com/Rafflebox+Privacy+Policy+-+US+and+Canada%2C+July+2022.pdf';
    },
    setTermsOfUseByLang() {
      return this.$i18n.locale === 'fr'
        ? 'https://rafflebox-docs.s3.ca-central-1.amazonaws.com/Rafflebox_Terms_of_Use_FR_01202022.pdf'
        : 'https://rafflebox-docs.s3.ca-central-1.amazonaws.com/Rafflebox+Site+Terms+of+Use+-+July%2C+2022.pdf';
    }
  }
};
</script>
<style scoped>
a {
  color: #8075ff;
}
</style>
