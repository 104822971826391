<template>
  <ul>
    <li>
      <router-link
        v-slot="{ navigate, isActive }"
        :to="{ path: '/raffle', query: { ...$route.query } }"
        custom
        class="btn btn-step btn-step--one"
      >
        <button :class="isActive && 'router-link-active'" role="link" @click="navigate" @keypress.enter="navigate">
          <span class="btn-step__number">1</span>
          <span class="btn-step__title">{{ firstTitle }}</span>
        </button>
      </router-link>
    </li>
    <li>
      <router-link
        v-slot="{ navigate, isActive }"
        :to="{ path: '/addon', query: { ...$route.query } }"
        custom
        class="btn btn-step btn-step--two"
      >
        <button :class="isActive && 'router-link-active'" role="link" @click="navigate" @keypress.enter="navigate">
          <span class="btn-step__number">2</span>
          <span class="btn-step__title">{{ secondTitle }}</span>
        </button>
      </router-link>
    </li>
    <li>
      <router-link
        v-slot="{ navigate, isActive }"
        :to="{ path: '/confirm', query: { ...$route.query } }"
        custom
        class="btn btn-step btn-step--three"
      >
        <button
          :class="isActive && 'router-link-active'"
          role="link"
          :disabled="shoppingCart.totalCents <= 0 ? true : false"
          @click="navigate"
          @keypress.enter="navigate"
        >
          <span class="btn-step__number">3</span>
          <span class="btn-step__title">Summary</span>
        </button>
      </router-link>
    </li>
  </ul>
</template>
<script lang="ts">
import ProjectMixin from '@/project-mixin';
import { Component, Mixins, Prop } from 'vue-property-decorator';

@Component
export default class StepNav extends Mixins(ProjectMixin) {
  @Prop({ required: true, default: 'Prize Raffle' })
  firstTitle!: string;

  @Prop({ required: true, default: '50/50 Addon' })
  secondTitle!: string;
}
</script>
<style lang="scss" scoped>
ul {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    min-width: 33.33333%;
    position: relative;
    padding: 0;
    background-color: $white;
  }
}

.btn-step {
  width: 100%;
  padding: 0.25rem;
  background-color: $white;
  border-radius: 0;
  border: 0;
  border-color: $white;

  &:hover,
  &:focus {
    color: $white;
    background-color: var(--buttonColor);
    box-shadow: none;
  }

  &__number {
    display: block;
    font-family: $heading;
    font-size: 2rem;
  }

  &__title {
    font-family: $body;
    font-size: 0.75rem;
  }

  &--one {
    text-align: left;
    border-left: 0;
  }

  &--three {
    text-align: right;
    border-right: 0;
  }
}

.router-link-active {
  color: $white;
  background-color: var(--buttonColor);
  cursor: default !important;
}

// Big phones & Phablets
@media screen and (min-width: 36rem) {
  ul {
    li {
      min-width: auto;
      width: 9rem;

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: calc(50% - 1px);
        left: calc(50% + 4.5rem);
        width: 100%;
        height: 2px;
        background-color: $slate;
      }
    }
  }

  .btn-step {
    border-left: 0.5rem solid $white;
    border-right: 0.5rem solid $white;
  }
}

// iPad Portrait
@media screen and (min-width: 48rem) {
  .btn-step {
    &__number {
      font-size: 2.25rem;
    }

    &--one,
    &--three {
      text-align: center;
    }
  }
}
</style>
