<template>
  <div>
    <CartContents />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import CartContents from '@/components/CartContents.vue';
import shoppingCart from '@/model/shopping-cart';

export default Vue.extend({
  components: { CartContents },
  data() {
    return {
      event: this.$store.state.event || {},
      addon: this.$store.state.addon || {},
      cart: shoppingCart.items()
    };
  }
});
</script>

<style lang="scss" scoped>
h2 {
  font-family: $heading;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem 0 1.5rem;
}

// iPad Portrait
@media screen and (min-width: 48rem) {
  h2 {
    font-size: 3rem;
    margin: 2rem 0 3rem;
  }
}

.age-confirmation {
  display: flex;
  margin: 0 0 -1px;
  padding: 1.25rem 1rem;
  font-family: $body;
  font-size: 1rem;
  border-top: 1px solid $smoke;
  border-bottom: 1px solid $smoke;
  label {
    display: flex;
    margin-bottom: 0;
    cursor: pointer;
    user-select: none;
    p {
      margin: 0.2rem 0 0 1rem;
      order: 2;
    }
    &:hover input ~ .checkmark {
      background-color: $smoke;
    }
    input:checked ~ .checkmark {
      background-color: var(--buttonColor);
    }
    input:checked ~ .checkmark::after {
      display: block;
    }
    .checkmark::after {
      left: 10px;
      top: 5px;
      width: 8px;
      height: 16px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
  input {
    position: absolute;
    height: 0;
    width: 0;
    cursor: pointer;
    opacity: 0;
  }
  .checkmark {
    position: relative;
    display: block;
    flex: 1 0 1.875rem;
    top: 0;
    left: 0;
    height: 1.875rem;
    width: 1.875rem;
    background-color: $white;
    border: 1px solid $slate;
    border-radius: 0.25rem;
    order: 1;
    &::after {
      content: '';
      position: absolute;
      display: none;
    }
  }
}
@media screen and (min-width: 48em) {
  .age-confirmation {
    label {
      font-size: 1.25rem;
      p {
        margin-top: 0;
      }
    }
  }
}
</style>
