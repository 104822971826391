import { CountryProvinceAgeModel, Country, ProvinceState } from '@rafflebox-technologies-inc/rafflebox-schema';

export const provinces: ProvinceState[] = CountryProvinceAgeModel.CA.provinceState.concat(
  CountryProvinceAgeModel.US.provinceState
);

const getProvinceStateOptions = (
  country: string,
  selectedProvinceState: string,
  validatePostalCodeLocation = true
): ProvinceState[] => {
  if (!validatePostalCodeLocation) {
    // allow the ticket buyer to select any province
    return CountryProvinceAgeModel[country as Country].provinceState;
  }

  // else, only allow the ticket buyer to select the province they live in
  const provinceState = CountryProvinceAgeModel[country as Country].provinceState.find(
    (provinceState) => provinceState.code === selectedProvinceState
  );

  return !provinceState ? [] : [provinceState];
};

export { getProvinceStateOptions };
