<template>
  <div class="input-country">
    <b-form-group label-for="input-country" class="mb-4">
      <!-- {{ country ? country : 'Please select an option' }} -->
      <template slot="label">Country</template>
      <b-form-select v-model="country" name="input-country" :options="countryOptions" data-vv-as="country">
      </b-form-select>
    </b-form-group>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CountryInput extends Vue {
  @Prop({ type: String, default: null }) value!: string;
  @Prop({ type: Boolean, default: false }) validatePostalCodeLocation!: boolean;

  beforeMount() {
    this.postalCodeCheck();
  }

  postalCodeCheck() {
    if (this.validatePostalCodeLocation) {
      this.countryOptions = [
        {
          value: this.country,
          text: this.country === 'CA' ? 'Canada' : 'United States'
        }
      ];
    }
  }

  countryOptions = [
    {
      value: 'CA',
      text: 'Canada'
    },
    {
      value: 'US',
      text: 'United States'
    }
  ];

  get country() {
    return this.value;
  }

  set country(value) {
    this.$emit('input', value);
  }
}
</script>
