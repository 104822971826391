<template>
  <div class="event-range">
    <label for="event-range">{{ title }}</label>
    <div class="event-range__controls">
      <b-form-input
        v-model="value"
        class="event-slider"
        type="range"
        variant="secondary"
        min="1"
        :max="maximum"
        @change="onValueChange"
      ></b-form-input>
      <b-form-input
        v-model="value"
        class="event-input"
        type="number"
        :max="maximum"
        min="1"
        @change="onValueChange"
      ></b-form-input>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from 'vue-property-decorator';
import ProjectMixin from '@/project-mixin';

@Component
export default class EventRange extends Mixins(ProjectMixin) {
  @Prop({ required: false, default: 'Number of Draws' })
  title!: string;

  @Prop({ required: false, default: 52 })
  maximum!: number;

  value = 1;

  mounted() {
    this.value = this.shoppingCart.numDraws;
  }

  onValueChange() {
    this.shoppingCart.numberOfDraws(this.value);
  }
}
</script>

<style lang="scss" scoped>
.event-range {
  &__controls {
    display: flex;
    align-items: center;
    padding: 0 1rem 1rem;
  }

  .event-slider {
    padding-right: 1rem;
  }

  .event-input {
    flex: 1 1 45px;
    padding: 0.3rem 0.5rem;
    height: calc(1.5em + 0.6rem + 2px);
    text-align: center;
    border-color: $slate;
    border-radius: 0.25rem;
  }

  label {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 36rem) {
    .event-input {
      flex: 1 1 65px;
    }
  }
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: var(--buttonColor);
  cursor: pointer;
}

.custom-range::-moz-range-thumb {
  background: var(--buttonColor);
  cursor: pointer;
}

/* Remove arrows from number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
