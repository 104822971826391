import Vue from 'vue';

import { TicketPackage as TicketPackageObject } from '@/lib/schema';
export class Checkout {
  public verifiedAge = false;
  public currentPackage: TicketPackageObject = {} as TicketPackageObject;
  public selectedEventMember = -1 as number | undefined;
  public selectedEventMemberName = '';

  updateCurrentPackage(tp: TicketPackageObject) {
    this.currentPackage = tp;
  }
}

const checkout = new Checkout();

export default Vue.observable(checkout);
